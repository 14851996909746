import { isEmpty } from 'lodash-es';
import { handleActions } from 'redux-actions';

import { getLines } from '../../../../../utils/get-lines';
import { getTargetEditor } from '../utils/get-target-editor';
import { actionTypes, ns } from './action-types';
import codeLockEpic from './code-lock-epic';
import codeStorageEpic from './code-storage-epic';
import completionEpic from './completion-epic';
import { createCurrentChallengeSaga } from './current-challenge-saga';
import { createExecuteChallengeSaga } from './execute-challenge-saga';
import nextChallengeEpic from './next-challenge-epic';
import { createIsAllowedChallnge } from './is-allowed-challenge-saga';
import { createUserAnswer } from './user-answer-saga';
export { ns };

const initialState = {
  canFocusEditor: true,
  attempts: 0,
  visibleEditors: {},
  challengeFiles: [],
  challengeMeta: {
    superBlock: '',
    phase: '',
    block: '',
    id: '',
    nextChallengePath: '/',
    nextChallengeId: null,
    prevChallengePath: '/',
    prevChallengeId: null,
    challengeType: -1
  },
  challengeTests: [],
  consoleOut: [],
  hasCompletedBlock: false,
  isCodeLocked: false,
  isBuildEnabled: true,
  isResetting: false,
  logsOut: [],
  modal: {
    completion: false,
    help: false,
    video: false,
    reset: false,
    projectPreview: false,
    shortcuts: false,
    limitedAccess: false,
    verifyPaidCourse: false,
    congratsCompleteAccess: false,
    nacionalCertificate: false,
    concludedCertificate: false,
    discordCommunity: false,
    notAllowedChallenge: false
  },
  portalWindow: null,
  showPreviewPortal: false,
  showPreviewPane: true,
  projectFormValues: {},
  successMessage: 'Happy Coding!',
  isAdvancing: false,
  challengeAnswers: [],
  completedChallengesLoading: false
};

export const epics = [
  codeLockEpic,
  completionEpic,
  codeStorageEpic,
  nextChallengeEpic
];

export const sagas = [
  ...createExecuteChallengeSaga(actionTypes),
  ...createCurrentChallengeSaga(actionTypes),
  ...createIsAllowedChallnge(actionTypes),
  ...createUserAnswer(actionTypes)
];

export const reducer = handleActions(
  {
    [actionTypes.createFiles]: (state, { payload }) => ({
      ...state,
      challengeFiles: payload,
      visibleEditors: { [getTargetEditor(payload)]: true }
    }),
    [actionTypes.updateFile]: (
      state,
      { payload: { fileKey, editorValue, editableRegionBoundaries } }
    ) => {
      const updates = {};
      // if a given part of the payload is null, we leave that part of the state
      // unchanged
      if (editableRegionBoundaries !== null)
        updates.editableRegionBoundaries = editableRegionBoundaries;
      if (editorValue !== null) updates.contents = editorValue;
      if (editableRegionBoundaries !== null && editorValue !== null)
        updates.editableContents = getLines(
          editorValue,
          editableRegionBoundaries
        );
      return {
        ...state,
        challengeFiles: state.challengeFiles.map(challengeFile =>
          challengeFile.fileKey === fileKey
            ? { ...challengeFile, ...updates }
            : { ...challengeFile }
        )
      };
    },
    [actionTypes.storedCodeFound]: (state, { payload }) => ({
      ...state,
      challengeFiles: payload
    }),
    [actionTypes.initTests]: (state, { payload }) => ({
      ...state,
      challengeTests: payload
    }),
    [actionTypes.updateTests]: (state, { payload }) => ({
      ...state,
      challengeTests: payload
    }),

    [actionTypes.initConsole]: (state, { payload }) => ({
      ...state,
      consoleOut: payload ? [payload] : []
    }),
    [actionTypes.updateConsole]: (state, { payload }) => ({
      ...state,
      consoleOut: state.consoleOut.concat(payload)
    }),
    [actionTypes.initLogs]: state => ({
      ...state,
      logsOut: []
    }),
    [actionTypes.updateLogs]: (state, { payload }) => ({
      ...state,
      logsOut: state.logsOut.concat(payload)
    }),
    [actionTypes.logsToConsole]: (state, { payload }) => ({
      ...state,
      consoleOut: isEmpty(state.logsOut)
        ? state.consoleOut
        : state.consoleOut.concat(payload, state.logsOut)
    }),
    [actionTypes.updateChallengeMeta]: (state, { payload }) => ({
      ...state,
      challengeMeta: { ...payload }
    }),
    [actionTypes.resetChallenge]: state => {
      const challengeFilesReset = state.challengeFiles.map(challengeFile => ({
        ...challengeFile,
        contents: challengeFile.seed.slice(),
        editableContents: getLines(
          challengeFile.seed,
          challengeFile.seedEditableRegionBoundaries
        ),
        editableRegionBoundaries:
          challengeFile.seedEditableRegionBoundaries.slice()
      }));
      return {
        ...state,
        currentTab: 2,
        challengeFiles: challengeFilesReset,
        challengeTests: state.challengeTests.map(({ text, testString }) => ({
          text,
          testString
        })),
        consoleOut: [],
        isResetting: true,
        attempts: 0
      };
    },
    [actionTypes.resetAttempts]: state => ({
      ...state,
      attempts: 0
    }),
    [actionTypes.stopResetting]: state => ({
      ...state,
      isResetting: false
    }),
    [actionTypes.updateSolutionFormValues]: (state, { payload }) => ({
      ...state,
      projectFormValues: payload
    }),

    [actionTypes.lockCode]: state => ({
      ...state,
      isCodeLocked: true
    }),
    [actionTypes.unlockCode]: state => ({
      ...state,
      isBuildEnabled: true,
      isCodeLocked: false
    }),
    [actionTypes.disableBuildOnError]: state => ({
      ...state,
      isBuildEnabled: false
    }),
    [actionTypes.setShowPreviewPortal]: (state, { payload }) => ({
      ...state,
      showPreviewPortal: payload
    }),
    [actionTypes.setShowPreviewPane]: (state, { payload }) => ({
      ...state,
      showPreviewPane: payload
    }),
    [actionTypes.storePortalWindow]: (state, { payload }) => ({
      ...state,
      portalWindow: payload
    }),
    [actionTypes.removePortalWindow]: state => ({
      ...state,
      portalWindow: null
    }),
    [actionTypes.updateSuccessMessage]: (state, { payload }) => ({
      ...state,
      successMessage: payload
    }),
    [actionTypes.setIsAdvancing]: (state, { payload }) => ({
      ...state,
      isAdvancing: payload
    }),
    [actionTypes.closeModal]: (state, { payload }) => ({
      ...state,
      modal: {
        ...state.modal,
        [payload]: false
      }
    }),
    [actionTypes.openModal]: (state, { payload }) => ({
      ...state,
      modal: {
        ...state.modal,
        [payload]: true
      }
    }),
    [actionTypes.executeChallenge]: state => ({
      ...state,
      currentTab: 3,
      attempts: state.attempts + 1
    }),
    [actionTypes.setEditorFocusability]: (state, { payload }) => ({
      ...state,
      canFocusEditor: payload
    }),
    [actionTypes.toggleVisibleEditor]: (state, { payload }) => {
      const visibleEditorsCount = Object.values(state.visibleEditors).filter(
        isVisible => isVisible
      ).length;

      if (visibleEditorsCount === 1 && state.visibleEditors[payload]) {
        return state;
      }
      return {
        ...state,
        visibleEditors: {
          ...state.visibleEditors,
          [payload]: !state.visibleEditors[payload]
        }
      };
    },
    [actionTypes.saveUserAnswer]: (state, { payload }) => {
      const { challengeAnswers } = state;

      const challengeAlreadyAnsweredIndex = challengeAnswers.findIndex(
        ({ id }) => id === payload.id
      );

      let updatedChallengeAnswers;

      if (payload.file) {
        if (challengeAlreadyAnsweredIndex >= 0) {
          const currentChallengeAnswer =
            challengeAnswers[challengeAlreadyAnsweredIndex];
          const fileIndex = currentChallengeAnswer.files.findIndex(
            answerFile => answerFile.file === payload.file
          );

          if (fileIndex !== -1) {
            currentChallengeAnswer.files[fileIndex] = {
              file: payload.file,
              userAnswer: payload.userAnswer
            };
            updatedChallengeAnswers = challengeAnswers.map((answer, index) =>
              index === challengeAlreadyAnsweredIndex
                ? currentChallengeAnswer
                : answer
            );
          } else {
            currentChallengeAnswer.files = [
              ...currentChallengeAnswer.files,
              { file: payload.file, userAnswer: payload.userAnswer }
            ];
            updatedChallengeAnswers = challengeAnswers.map((answer, index) =>
              index === challengeAlreadyAnsweredIndex
                ? currentChallengeAnswer
                : answer
            );
          }
        } else {
          updatedChallengeAnswers = [
            ...challengeAnswers,
            {
              id: payload.id,
              files: [{ file: payload.file, userAnswer: payload.userAnswer }]
            }
          ];
        }
      } else {
        if (challengeAlreadyAnsweredIndex >= 0) {
          // Update existing answer
          updatedChallengeAnswers = challengeAnswers.map((answer, index) =>
            index === challengeAlreadyAnsweredIndex ? payload : answer
          );
        } else {
          // Add new answer
          updatedChallengeAnswers = [...challengeAnswers, payload];
        }
      }

      return {
        ...state,
        challengeAnswers: updatedChallengeAnswers
      };
    },
    [actionTypes.isAllowedChallenge]: (state, { payload }) => ({
      ...state,
      challenge: payload
    }),
    [actionTypes.setCompletedChallengesLoading]: (state, { payload }) => ({
      ...state,
      completedChallengesLoading: payload
    })
  },
  initialState
);
